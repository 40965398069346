import * as React from "react"
import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Recent from "../components/Recent"

import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const {
    mdx: {
      frontmatter: { title, description },
      body,
      excerpt,
    },
    allPosts: { recentPosts },
  } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={title} description={description || excerpt} />

      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <MDXRenderer>{body}</MDXRenderer>
        <hr />
        <Recent recentPosts={recentPosts} />
        <footer>
          <Bio />
        </footer>
      </article>
    </Layout>
  )
}

export default BlogIndex

export const homePageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mdx(slug: { eq: "landing-page/" }) {
      id
      excerpt
      body
      frontmatter {
        title
        description
        date
        category
      }
      wordCount {
        words
      }
      timeToRead
      slug
    }
    allPosts: allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      recentPosts: nodes {
        id
        excerpt
        frontmatter {
          title
          category
          description
          date(formatString: "MMM Do, YYYY")
          tags
        }
        slug
      }
    }
  }
`
