import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import "../style.css"

const Recent = ({ recentPosts }) => {
  //   const recentPosts = data.allMdx.recentPosts

  return (
    <div className="recent">
      <h4>Recent Posts</h4>
      <ol style={{ listStyle: `none` }}>
        {recentPosts.map(post => {
          const { title, category, description, date, tags } = post.frontmatter
          return (
            <li key={post.id}>
              <RecentPost>
                <h5>
                  <Link
                    to={`/posts/${post.slug}`}
                    className="content-link"
                    alt={title}
                  >
                    {title}
                  </Link>
                </h5>
                <p>
                  {description}
                  <hr />
                  <DateText>{date}</DateText>
                </p>
              </RecentPost>
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default Recent

const RecentPost = styled.div`
h5 {
    font-size: var(--fontSize-2);
    line-height: var(--lineHeight-condensed);
    color: var(--color-component-green);
  }
  a:{
    text-decoration: none;
  }
  a:hover,
  a:focus {
    text-decoration: none;
    color: var(--color-component-blue);
  }
  }
`
const DateText = styled.span`
  color: var(--color-heading);
`
